export default {
  colors: {
    primaryDark: '#0b0d4e',
    primary: '#1f2a5b',
    primaryVivid: '#303e7f',
    primaryLight: '#646fa2',
    primaryPale: '#8593d1',
    primaryText: '#1f2a5b',

    salmon: '#fa8072',

    secondaryDark: '#1630ab',
    secondary: '#4259c1',
    secondaryVivid: '#5b74e5',
    secondaryLight: '#95b4fc',
    secondaryPale: '#e5ecff',

    tertiary: '#59e2f7',
    tertiaryPale: '#cbeff4',
    tertiaryLight: '#95B4FC99',

    dark: '#1f2433',
    greyDark: '#5e6171',
    grey: '#a0a4b4',
    greyLight: '#e4e6f1',
    greyExtraLight: '#edeef4',
    greyPale: '#f9faff',
    light: '#ffffff',

    positive: '#50cba1',
    positivePale: '#d3f9ec',
    negative: '#f3698a',
    negativePale: '#ffe6ec',

    accentRed: '#f3698a',
    accentRedPale: '#ffeef2',
    accentGreen: '#50cba1',
    accentGreenPale: '#d1f6e9',
    accentBlue: '#59e2f7',
    accentBluePale: '#dbfaff',
    accentYellow: '#ffcc1e',
    accentYellowPale: '#fff5d9',
    accentGray: '#d9dfeb',
    accentGrayPale: '#f7f8fe',
    accentOrange: '#f39969'
  },
  blockColors: {
    brinkPink: '#f3698a',
    freeSpeechBlue: '#4259c1',
    lightSkyBlue: '#95b4fc',
    linkWater: '#c4c7d4',
    mediumAquamarine: '#50cba1',
    mediumSlateBlue: '#8469f3',
    midnightExpress: '#1f2433',
    sandyBrown: '#f39969',
    sunglow: '#ffcc1e',
    turquoise: '#48d4ea'
  },
  onCallBlockColors: {
    melrose: '#b4a2ff',
    mediumSlateBlue: '#8469f3',
    electricViolet: '#6f31f8',
    linkWater: '#c4c7d4',
    santasGray: '#9da0af',
    midGray: '#676874',
    periwinkle: '#c1d2ff',
    jordyBlue: '#88a9f7',
    freeSpeechBlue: '#4259c1',
    lightSunglow: '#ffe89a',
    sunglow: '#ffcc1e',
    darkSunglow: '#cc9e00',
    lightTurquoise: '#a0f2ff',
    turquoise: '#48d4ea',
    darkTurquoise: '#009eb7',
    lightSandyBrown: '#ffd1b9',
    sandyBrown: '#f39969',
    darkSandyBrown: '#e25f1a',
    lightAquamarine: '#84f5cf',
    mediumAquamarine: '#50cba1',
    darkAquamarine: '#00a26b',
    lightBrinkPink: '#ffbccc',
    brinkPink: '#f3698a',
    darkBrinkPink: '#d50033'
  },
  zIndexes: {
    backdrop: -2,
    background: -1,
    elements: 1,
    footer: 2,
    inputIcon: 10,
    table: 15,
    popup: 20,
    modal: 100,
    portal: 999,
    alert: 1000
  },
  typography: {
    fontFamilyVariants: {
      primary: 'Poppins'
    },
    fontSizeVariants: {
      xxxLarge: 28,
      xxLarge: 24,
      xLarge: 20,
      large: 18,
      medium: 16,
      small: 14,
      xSmall: 12,
      xxSmall: 11,
      xxxSmall: 10
    },
    lineHeightVariants: {
      none: 1,
      compact: 1.4,
      cozy: 1.6,
      normal: 1.7
    },
    fontWeightVariants: {
      extralight: 200,
      light: 300,
      regular: 400,
      medium: 500,
      semibold: 600,
      bold: 700
    },
    fontLetterSpacingVariants: {
      normal: 'normal',
      compact: 1,
      cozy: 1.2
    }
  },
  units: {
    cardLeftPadding: 78,
    cardRightPadding: 55
  }
}
