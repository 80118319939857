import React from 'react'
import { useTranslation } from 'react-i18next'

import AuthLayout from 'components/layouts/AuthLayout'
import NavButton from 'components/buttons/NavButton'
import TextLink from 'components/links/TextLink'

const marketingSiteUrl = import.meta.env.VITE_MARKETING_SITE_BASE_URL

function SignupLayout(props) {
  const { t } = useTranslation()
  const { isLoggedIn, hideNavbar, ...rest } = props

  const navbarItems = hideNavbar ? null : (
    <>
      <TextLink
        label={t('signupLayout.navbar.about')}
        href={`${marketingSiteUrl}/about-us`}
        color="light"
        weight="medium"
        size="xSmall"
        letterSpacing="normal"
      />
      <TextLink
        label={t('signupLayout.navbar.contact')}
        href={`${marketingSiteUrl}/request-a-demo`}
        color="light"
        weight="medium"
        size="xSmall"
        letterSpacing="normal"
      />
      {!isLoggedIn && (
        <NavButton compact label={t('signupLayout.navbar.login')} to="/login" />
      )}
    </>
  )

  return <AuthLayout navbarItems={navbarItems} {...rest} />
}

export default SignupLayout
