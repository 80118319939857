import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'

import FieldError from 'components/inputs/FieldError'
import PhoneInput from 'react-phone-input-2'
import PropTypes from 'prop-types'
import { regionAcronyms, DB_USER_REGION_KEY } from 'models/Account'
import usePersistedState from 'hooks/usePersistedState'
import { getProductTheme } from 'lib/CheckProduct'

import { transitionSimple } from 'styles/mixins'
import rgba from 'lib/rgba'
import 'react-phone-input-2/lib/style.css'

const heightVariants = {
  small: 40,
  medium: 50,
  regular: 54,
  large: 70
}

// Open issue in react-phone-input-2 with vite
// https://github.com/bl00mber/react-phone-input-2/issues/609
// For more info on this,
//  please refer https://github.com/vitejs/vite/issues/2139#issuecomment-1024852072
const PhoneInputComponent = PhoneInput.default ? PhoneInput.default : PhoneInput

const useStyles = createUseStyles(
  ({ input, colors, typography, zIndexes }) => ({
    formattedPhoneInput: props => ({
      '&.form-control': {
        ...transitionSimple(),

        backgroundColor: colors.light,
        border: `1px solid ${colors.greyLight}`,
        borderColor: colors.greyLight,
        borderRadius: 12,
        color: colors.dark,
        fontFamily: typography.fontFamilyVariants.primary,
        fontSize: typography.fontSizeVariants[props.fontSize],
        fontWeight: typography.fontWeightVariants[props.weight],
        height: heightVariants[props.variant],
        opacity: props.isDisabled && 0.5,
        outline: 'none',
        padding: [15, 20, 15, 20],
        paddingBottom: props.size === 'small' ? 10 : 15,
        paddingLeft: 50,
        paddingRight: props.showInputValidation && 45,
        paddingTop: props.size === 'small' ? 10 : 15,
        pointerEvents: props.isDisabled && 'none',
        width: '100% !important',

        '&::placeholder': {
          color: colors.grey,
          opacity: 1
        },

        '&:focus::placeholder': {
          color: !props.input?.value && colors.dark,
          opacity: 0.3
        },

        '&:hover, &:focus': {
          borderColor: !props.input?.value && 'transparent',
          boxShadow: `0px 8px 15px ${rgba(colors.primary, 0.05)}`
        }
      }
    }),
    formattedPhoneContainer: {
      position: 'relative',

      '& .country-list': {
        backgroundColor: colors.light,
        borderTop: `1px solid ${colors.greyLight}`,
        borderRadius: '0 0 12px 12px',
        boxShadow: props =>
          props.variant === 'large' ?
            `0px 8px 15px ${rgba(colors.primary, 0.5)}` :
            `0px 8px 15px ${rgba(colors.primary, 0.05)}`,
        marginTop: -1,
        marginBottom: 0,
        zIndex: zIndexes.popup
      },
      '& .dial-code': {
        color: `${rgba(colors.grey, 0.7)} `,
        fontSize: '90%'
      }
    },
    phoneInputContainer: {
      borderRadius: 12,
      fontFamily: 'Poppins',
      fontSize: 12,
      marginBottom: props =>
        !props.inline && (props.showValidationMessage ? 30 : 20),
      position: 'relative',
      width: '100%',

      '&:hover, &:focus': {
        boxShadow: props =>
          props.variant !== 'large' || input?.value ?
            'none' : `0px 20px 40px ${rgba(colors.primary, 0.46)} `
      },

      '& .country-list': {
        zIndex: 1
      }
    },
    selectCountryButton: {
      '& .selected-flag': {
        paddingLeft: 11
      },
      '& .selected-flag, &.flag-dropdown': {
        backgroundColor: `${rgba(colors.greyLight, 0.2)} `,
        borderColor: 'transparent',
        borderRadius: '12px 0px 0px 12px'
      },
      '&.open, & .selected-flag.open': {
        borderRadius: '12px 0px 0px 0px !important'
      },
      '& .arrow, & .arrow.up': {
        borderLeft: 'none !important',
        borderRight: 'none !important',
        borderTop: 'none !important'
      }
    },
    errorContainer: {
      '& div': {
        zIndex: 'unset'
      }
    }
  })
)

function FormattedPhoneInput({
  defaultCountryCode,
  defaultDialCode,
  input,
  meta,
  placeholder,
  showValidationMessage,
  variant,
  ...props
}) {
  const [region] = usePersistedState(DB_USER_REGION_KEY, 'United States')
  const countryCode = regionAcronyms[region] || defaultCountryCode

  const touchedField = meta && (meta.touched || meta.submitFailed)
  const error = touchedField && (meta.error || meta.submitError)

  const [selectedCodes, setSelectedCodes] = useState({
    dialCode: defaultDialCode,
    countryCode
  })

  const inputProps = {
    ...input,
    placeholder,
    onChange: (value, details) => {
      if (details.dialCode !== selectedCodes.dialCode) {
        input.onChange(`+${details.dialCode}`)
      } else {
        const prepend = value !== '' ? '+' : `+${details.dialCode}`
        input.onChange(`${prepend}${value}`)
      }

      setSelectedCodes({
        dialCode: details.dialCode,
        countryCode: details.countryCode
      })
    },
    onFocus: () => {
      if (!selectedCodes.countryCode && input.value.length < 4) {
        setSelectedCodes({
          dialCode: defaultDialCode,
          countryCode
        })
      }
    }
  }

  const classes = useStyles({
    showValidationMessage,
    variant,
    ...props
  })

  return (
    <div className={classes.formattedPhoneContainer}>
      <div className={classes.errorContainer}>
        {showValidationMessage && error && <FieldError error={error} />}
      </div>
      <PhoneInputComponent
        buttonClass={classes.selectCountryButton}
        country={selectedCodes.countryCode}
        countryCodeEditable={false}
        containerClass={classes.phoneInputContainer}
        inputClass={classes.formattedPhoneInput}
        masks={{ us: '(...) ...-....', au: '. .... ....'}}
        preferredCountries={['us', 'ca', 'gb', 'au']}
        preserveOrder={['preferredCountries']}
        regions={['america', 'europe', 'asia', 'oceania']}
        {...inputProps}
        disableCountryGuess
      />
    </div>
  )
}

FormattedPhoneInput.defaultProps = {
  defaultCountryCode: 'us',
  defaultDialCode: '1',
  fontSize: 'xSmall',
  placeholder: 'Phone Number',
  showValidationMessage: false,
  variant: 'medium',
  weight: 'regular'
}

FormattedPhoneInput.propTypes = {
  defaultCountryCode: PropTypes.string,
  defaultDialCode: PropTypes.string,
  fontSize: PropTypes.oneOf(
    Object.keys(getProductTheme().typography.fontSizeVariants)
  ),
  placeholder: PropTypes.string,
  showValidationMessage: PropTypes.bool,
  variant: PropTypes.string,
  weight: PropTypes.string
}

export default FormattedPhoneInput
