import PropTypes from 'prop-types'
import React, { Fragment } from 'react'
import classNames from 'classnames'
import { createUseStyles } from 'react-jss'

import Text from 'components/typography/Text'
import useHover from 'hooks/useHover'
import usePopper from 'hooks/usePopper'

import accessibleOnClick from 'lib/accessibleOnClick'

const useStyles = createUseStyles(({ colors, zIndexes }) => ({
  target: {
    cursor: 'pointer',
    display: ({ inline }) => (inline ? 'inline' : 'block'),
    height: '100%'
  },
  popup: {
    animation: '$fadeIn 0.5s ease',
    background: ({ backgroundColor }) => colors[`${backgroundColor}`],
    borderRadius: 12,
    minWidth: 110,
    padding: [10, 15],
    position: 'absolute',
    zIndex: zIndexes.popup,

    '&::before': {
      content: ({ includeGraceArea }) => (includeGraceArea ? '""' : 'none'),
      position: 'absolute',
      top: -10,
      left: -10,
      right: -10,
      bottom: -10,
      zIndex: -1
    },

    '&[data-popper-placement^="bottom"]': {
      '& > $arrow': {
        borderColor: ({ backgroundColor }) =>
          `transparent transparent ${colors[`${backgroundColor}`]} transparent`,
        borderWidth: [0, 5, 5, 5],
        left: 'calc(50% - 5px)',
        top: -4.5
      }
    },

    '&[data-popper-placement^="top"]': {
      '& > $arrow': {
        borderColor: ({ backgroundColor }) =>
          `${colors[`${backgroundColor}`]} transparent transparent transparent`,
        borderWidth: [5, 5, 0, 5],
        bottom: -5,
        left: 'calc(50% - 5px)'
      }
    },

    '&[data-popper-placement^="left"]': {
      '& > $arrow': {
        borderColor: ({ backgroundColor }) =>
          `transparent transparent transparent ${colors[`${backgroundColor}`]}`,
        borderWidth: [5, 0, 5, 5],
        right: -5,
        top: 'calc(50% - 20px)'
      }
    },

    '&[data-popper-placement^="right"]': {
      '& > $arrow': {
        left: -5,
        borderColor: ({ backgroundColor }) =>
          `transparent ${colors[`${backgroundColor}`]} transparent transparent`,
        borderWidth: [5, 5, 5, 0],
        top: 'calc(50% - 22px)'
      }
    }
  },

  arrow: {
    borderStyle: 'solid',
    marginLeft: props => props.arrowOffset,
    position: 'absolute'
  },

  '@keyframes fadeIn': {
    '0%': { opacity: 0 },
    '100%': { opacity: 1 }
  }
}))

function Tooltip({
  alignText,
  children,
  delay,
  inline,
  isParentHovering,
  placement,
  title: TooltipElem,
  titleSize,
  titleProps,
  textTransform,
  arrowOffset,
  backgroundColor,
  onTargetClick,
  strategy,
  onClick,
  align,
  includeGraceArea,
  ...props
}) {
  const classes = useStyles({
    inline,
    backgroundColor,
    arrowOffset,
    includeGraceArea
  })
  const [showPopup, hoverProps] = useHover(isParentHovering, delay)

  const TooltipOffSet =
    placement.startsWith('bottom') || placement.startsWith('top') ? 5 : 15

  const { targetRef, popupRef, arrowRef } = usePopper({
    placement,
    strategy,
    modifiers: [
      {
        name: 'offset',
        options: {
          offset: [0, TooltipOffSet]
        }
      }
    ]
  })

  return (
    <Fragment>
      {isParentHovering && showPopup && (
        <div
          className={classNames(classes.popup, props.className)}
          ref={popupRef}
          {...hoverProps}
          role="tooltip"
        >
          {typeof TooltipElem === 'function' ? (
            <TooltipElem {...titleProps} />
          ) : (
            <div {...accessibleOnClick(event => onTargetClick(event))}>
              <Text
                align={align}
                weight="medium"
                color="light"
                transform={textTransform}
                size={titleSize}
              >
                {TooltipElem}
              </Text>
            </div>
          )}
          <span className={classes.arrow} ref={arrowRef} />
        </div>
      )}
      <div
        className={classNames(classes.target, props.targetClassName)}
        ref={targetRef}
        {...hoverProps}
        {...accessibleOnClick(event => onClick(event), null)}
      >
        {children}
      </div>
    </Fragment>
  )
}

Tooltip.propTypes = {
  delay: PropTypes.number,
  inline: PropTypes.bool,
  isParentHovering: PropTypes.bool,
  placement: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.func, PropTypes.node])
    .isRequired,
  titleProps: PropTypes.object,
  titleSize: PropTypes.oneOf(['small', 'xSmall', 'xxSmall', 'xxxSmall']),
  backgroundColor: PropTypes.string,
  arrowOffset: PropTypes.number,
  onTargetClick: PropTypes.func,
  strategy: PropTypes.string,
  onClick: PropTypes.func,
  align: PropTypes.oneOf(['left', 'center', 'right']),
  includeGraceArea: PropTypes.bool
}

Tooltip.defaultProps = {
  delay: 0,
  inline: true,
  isParentHovering: true,
  placement: 'auto',
  titleProps: {},
  titleSize: 'small',
  backgroundColor: 'primary',
  arrowOffset: 0,
  onTargetClick: () => {},
  onClick: () => {},
  strategy: 'absolute',
  align: 'center',
  includeGraceArea: false
}

export default Tooltip
