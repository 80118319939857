import React from 'react'
import { createUseStyles } from 'react-jss'

import Stack from 'components/base/Stack'

const useStyles = createUseStyles({
  stack: {
    padding: [20, 70]
  }
})

function FullScreenModalFooter({ children, variant, ...props }) {
  const classes = useStyles(props)

  return (
    <Stack justify="flex-end" className={classes.stack}>
      {children}
    </Stack>
  )
}

export default FullScreenModalFooter
