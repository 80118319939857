import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useEffect } from 'react'
import TextareaAutosize from 'react-textarea-autosize'
import { createUseStyles } from 'react-jss'
import Cleave from 'cleave.js/react'

import FieldError from 'components/inputs/FieldError'
import FontIcon from 'components/base/FontIcon'
import rgba from 'lib/rgba'
import Text from 'components/typography/Text'
import { getProductTheme } from 'lib/CheckProduct'
import { transitionSimple } from 'styles/mixins'
import NetworkBanner from 'components/networkBanner/NetworkBanner'

const useStyles = createUseStyles(({ colors, typography, zIndexes }) => {
  const inputColor = ({ showValidationUI, input, meta }) => {
    if (showValidationUI && meta) {
      if (meta.error && !meta.pristine) {
        return colors.negative
      }

      if (!input.value.trim()) {
        return colors.dark
      }

      return colors.positive
    }

    return colors.dark
  }

  const borderColor = ({ showValidationUI, input, meta }) => {
    if (showValidationUI && meta) {
      if (meta.error && !meta.pristine) {
        return colors.negativePale
      }

      if (meta.pristine || !input.value.trim()) {
        return colors.greyLight
      }

      return colors.positivePale
    }

    return colors.greyLight
  }

  return {
    inputWrapper: {
      marginBottom: props =>
        !props.inline && (props.showValidationMessage ? 30 : 20),
      position: 'relative',
      width: props => props.width,
      zIndex: zIndexes.elements
    },
    title: {
      marginBottom: 10
    },
    input: {
      ...transitionSimple(),

      '&::-moz-selection': {
        /* Code for Firefox */ background: colors.tertiaryLight
      },

      '&::selection': {
        background: colors.tertiaryLight
      },

      backgroundColor: colors.light,
      border: `1px solid ${colors.greyLight}`,
      borderColor: props => borderColor(props),
      borderRadius: ({ flatBorderBottom }) =>
        flatBorderBottom ? '12px 12px 0 0' : 12,
      color: props => inputColor(props),
      fontFamily: typography.fontFamilyVariants.primary,
      fontSize: props => typography.fontSizeVariants[props.fontSize],
      fontWeight: typography.fontWeightVariants.medium,
      height: props => props.type === 'textarea' && '100%',
      opacity: props => props.isDisabled && 0.5,
      outline: 'none',
      padding: [15, 20, 15, 20],
      paddingTop: props => (props.size === 'small' ? 10 : 15),
      paddingBottom: props => (props.size === 'small' ? 10 : 15),
      paddingRight: props => props.showInputValidation && 45,
      pointerEvents: props => props.isDisabled && 'none',
      width: '100%',
      flex: props => props.fullHeight && 1,
      minHeight: props => props.minRows && props.minRows * 50,
      maxHeight: props => props.fixedHeight && props.minRows * 50,
      resize: ({ type, nonExpandable }) =>
        type === 'textarea' && nonExpandable ? 'none' : undefined,

      '&::placeholder': {
        color: colors.grey,
        opacity: 1
      },

      '&:focus::placeholder': {
        color: props => !props.input.value && colors.dark,
        opacity: 0.3
      },

      '&:hover, &:focus': {
        borderColor: props =>
          props.fullHeight
            ? borderColor(props)
            : props.input.value && 'transparent',
        boxShadow: props =>
          props.fullHeight
            ? 'unset'
            : `${rgba(colors.primary, 0.05)} 0px 8px 15px`
      }
    },
    statusIcon: {
      position: 'absolute',
      right: 0,
      top: '50%',

      '& .icon': {
        position: 'absolute',
        right: 20,
        transform: 'translateY(-50%)'
      }
    },
    subText: {
      position: 'absolute',
      display: 'flex',
      justifyContent: 'flex-end',
      right: 0,
      top: '105%',
      zIndex: zIndexes.backdrop
    }
  }
})

const TextInput = React.forwardRef(
  (
    {
      className,
      inline,
      input,
      inputOnChange,
      isDisabled,
      meta,
      showValidationMessage,
      showValidationUI,
      title,
      type,
      includeMask,
      networkCheck,
      options,
      subText,
      minRows,
      flatBorderBottom,
      inputWrapperClassName,
      autoCapitalization,
      ...props
    },
    ref
  ) => {
    const touchedField = meta && (meta.touched || meta.submitFailed)
    const error = touchedField && (meta.error || meta.submitError)
    const showInputValidation = touchedField && showValidationUI
    const errorIcon = showValidationMessage ? 'warning' : 'error'
    const InputTag = type === 'textarea' ? TextareaAutosize : 'input'

    useEffect(() => {
      if (ref && ref.current && props.autoFocus) {
        // Focus the input element when the component mounts
        ref.current.focus()

        // Get the length of the text in the input element
        const textLength = ref.current.value.length

        // Place the caret (cursor) at the end of the text
        ref.current.setSelectionRange(textLength, textLength)
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const classes = useStyles({
      inline,
      input,
      isDisabled,
      meta,
      showInputValidation,
      showValidationMessage,
      showValidationUI,
      type,
      minRows,
      flatBorderBottom,
      ...props
    })

    const capitalizeFirstLetter = value => {
      if (!value) return value
      return value.charAt(0).toUpperCase() + value.slice(1)
    }

    const inputProps = {
      ...input,
      onChange: e => {
        const { selectionStart } = e.target

        if (autoCapitalization) {
          const capitalizedValue = capitalizeFirstLetter(e.target.value)
          input.onChange({ ...e, target: { ...e.target, value: capitalizedValue } })

          if (inputOnChange) {
            inputOnChange(capitalizedValue)
          }

          requestAnimationFrame(() => {
            if (ref && ref.current) {
              ref.current.setSelectionRange(selectionStart, selectionStart)
            }
          })
        } else {
          input.onChange(e)
          if (inputOnChange) inputOnChange(e.target.value)
        }
      }
    }

    return (
      <div className={classNames(classes.inputWrapper, inputWrapperClassName)}>
        {title && (
          <Text
            size="xSmall"
            weight="regular"
            color="grey"
            className={classes.title}
          >
            {title.toUpperCase()}
          </Text>
        )}
        {includeMask ? (
          <Cleave
            // For options, see https://github.com/nosir/cleave.js/blob/master/doc/options.md
            options={options}
            value={input}
            onChange={inputOnChange}
            className={classNames(classes.input, className)}
            disabled={isDisabled}
            {...inputProps}
            {...props}
          />
        ) : (
          <>
            {networkCheck && (
              <NetworkBanner
                position="relative"
              />
            )}
            <InputTag
              ref={ref}
              type={type}
              className={classNames(classes.input, className)}
              disabled={isDisabled}
              {...inputProps}
              {...props}
            />
          </>
        )}
        {showInputValidation && (
          <div className={classes.statusIcon}>
            <FontIcon
              key={error}
              color={error ? 'negative' : 'positive'}
              name={`${error ? errorIcon : 'success'}-small`}
            />
          </div>
        )}
        {showValidationMessage && error && <FieldError error={error} />}
        {subText && (
          <Text
            size="xSmall"
            weight="regular"
            color="grey"
            className={classes.subText}
          >
            {subText}
          </Text>
        )}
      </div>
    )
  }
)

TextInput.propTypes = {
  className: PropTypes.string,
  inputWrapperClassName: PropTypes.string,
  fullHeight: PropTypes.bool,
  fontSize: PropTypes.oneOf(
    Object.keys(getProductTheme().typography.fontSizeVariants)
  ),
  inline: PropTypes.bool,
  inputOnChange: PropTypes.func,
  isDisabled: PropTypes.bool,
  showValidationMessage: PropTypes.bool,
  showValidationUI: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'regular']),
  type: PropTypes.string,
  includeMask: PropTypes.bool,
  networkCheck: PropTypes.bool,
  options: PropTypes.object,
  subText: PropTypes.string,
  minRows: PropTypes.number,
  width: PropTypes.string,
  nonExpandable: PropTypes.bool,
  flatBorderBottom: PropTypes.bool,
  autoCapitalization: PropTypes.bool
}

TextInput.defaultProps = {
  className: null,
  inputWrapperClassName: null,
  fullHeight: false,
  fontSize: 'xSmall',
  inline: false,
  inputOnChange: null,
  isDisabled: false,
  showValidationMessage: false,
  showValidationUI: false,
  size: 'regular',
  type: 'text',
  includeMask: false,
  networkCheck: false,
  options: {},
  subText: null,
  minRows: 0,
  width: '100%',
  nonExpandable: false,
  flatBorderBottom: false,
  autoCapitalization: false
}

export default TextInput
