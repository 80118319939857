/* eslint-disable react-hooks/exhaustive-deps */
import Cookie from 'js-cookie'
import React, { useEffect, useMemo, useState } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { useHistory, useLocation } from 'react-router-dom'

import AppContext from 'contexts/AppContext'
import AppRouter from 'components/router/AppRouter'
import BugsnagErrorHandler from 'components/base/BugsnagWrapper'
import getDefaultTab from 'lib/getDefaultTab'
import Loader from 'components/loaders/Loader'
import Pendo from 'components/analytics/Pendo'
import useClientQuery from 'hooks/useClientQuery'
import useLazyQuery from 'hooks/useLazyQuery'
import useScrollTo from 'hooks/useScrollTo'
import useURLParams from 'hooks/useURLParams'
import {
  ACCESSIBLE_WORKFLOW_ITEMS_QUERY,
  WORKFLOW_ITEMS_QUERY
} from 'models/Workflow'
import {
  CURRENT_ACCOUNT_QUERY,
  RESOLVED_FEATURES_QUERY,
  RESOLVED_SETTINGS_QUERY,
  UNREAD_NOTIFICATIONS_COUNT_QUERY
} from 'models/Account'
import { FilterProvider } from 'contexts/FilterContext'
import { GATagGlobal } from 'components/analytics/GATagManager'
import { GET_AUTH } from 'client/state/auth'
import { getCurrentProduct, helmetItems } from 'lib/CheckProduct'
import { RequiredFieldsProvider } from 'contexts/RequiredFieldsContext'
import { ScrollProvider } from 'contexts/ScrollContext'
import { SOLUTIONS_QUERY } from 'models/Solution'

import 'restAPI/mockServer'

const UNREAD_NOTIFICATIONS_QUERY_POLLING_INTERVAL = 20000

function App() {
  const location = useLocation()
  const history = useHistory()
  const urlParams = useURLParams()
  const referrer = urlParams.get('referrer')

  const [showLoader, setShowLoader] = useState(true)

  const { data: { auth: { currentToken } = {} } = {} } =
    useClientQuery(GET_AUTH)

  useEffect(() => {
    if (currentToken) {
      Cookie.set('access-token', currentToken)
    } else {
      Cookie.remove('access-token')
    }
  }, [currentToken])

  const [getApp, { data: accountData = {}, loading: loadingAppQuery }] =
    useLazyQuery(CURRENT_ACCOUNT_QUERY, {
      onCompleted: response => {
        setShowLoader(false)

        const defaultTab = getDefaultTab(
          response.currentAccount.resolvedSettings.default_tab,
          referrer
        )

        if (!response.currentAccount.eulaSignedAt) {
          history.push({
            pathname: '/accept-eula',
            ...(referrer ? { search: `?referrer=${referrer}` } : {})
          })
        } else if (
          location.pathname === '/organization-login' ||
          location.pathname.includes('/verify') ||
          location.pathname === '/oauth-redirect'
        ) {
          history.push(defaultTab)
        }
      }
    })

  const [
    getUnreadNotificationsCount,
    { data: unreadNotificationsCountData = {} }
  ] = useLazyQuery(UNREAD_NOTIFICATIONS_COUNT_QUERY, {
    pollInterval: UNREAD_NOTIFICATIONS_QUERY_POLLING_INTERVAL
  })

  const [
    getAccessibleWorkflowItems,
    {
      data: accessibleWorkflowItemsData = {},
      refetch: refetchAccessibleWorkflowItems
    }
  ] = useLazyQuery(ACCESSIBLE_WORKFLOW_ITEMS_QUERY)

  const [
    getResolvedFeatures,
    {
      data: resolvedFeaturesData = {},
      refetch: refetchResolvedFeatures,
      loading: loadingAccountFeatures
    }
  ] = useLazyQuery(RESOLVED_FEATURES_QUERY)

  const [
    getResolvedSetting,
    {
      data: resolvedSettingData = {},
      refetch: refetchResolvedSettings,
      loading: loadingAccountSetting
    }
  ] = useLazyQuery(RESOLVED_SETTINGS_QUERY)

  const [getAllSolutions, { data: { solutions = [] } = {} }] =
    useLazyQuery(SOLUTIONS_QUERY)

  // Have to desturcture the currentAccount here since from Apollo Client > 3.0
  // the useLazyQuery hook return null for data on first mount
  const { currentAccount } = accountData || {}

  const [getWorkflowItems] = useLazyQuery(WORKFLOW_ITEMS_QUERY)

  const appQueryLoading =
    loadingAppQuery || loadingAccountFeatures || loadingAccountSetting

  useEffect(() => {
    if (currentToken) {
      getApp()
      getResolvedFeatures()
      getResolvedSetting()
      getUnreadNotificationsCount()
      getWorkflowItems()
      getAccessibleWorkflowItems()
      getAllSolutions()
    } else {
      setShowLoader(false)
    }
  }, [
    currentToken,
    getApp,
    getWorkflowItems,
    getAccessibleWorkflowItems,
    getUnreadNotificationsCount,
    getResolvedFeatures,
    getResolvedSetting,
    getAllSolutions
  ])

  useScrollTo({ top: 0, left: 0, behavior: 'auto' })

  const isLoggedIn = !!(currentToken && currentAccount)
  const hasUnreadNotifications =
    unreadNotificationsCountData?.currentAccount?.unreadNotificationsCount
  const accessibleWorkflowItemsCount =
    accessibleWorkflowItemsData?.currentAccount?.accessibleWorkflowItemIds
      ?.length

  const resolvedFeatures = Object.fromEntries(
    Object.entries(
      resolvedFeaturesData?.currentAccount?.resolvedFeatures || {}
    ).filter(([, val]) => val === true)
  )
  const resolvedFeaturesCount = Object.keys(resolvedFeatures).length
  const appContextValue = useMemo(
    () => ({
      currentAccount,
      resolvedFeatures,
      refetchResolvedFeatures,
      resolvedSettings: resolvedSettingData?.currentAccount?.resolvedSettings,
      refetchResolvedSettings,
      accessibleWorkflowItemsData: {
        accessibleWorkflowItemIds:
          accessibleWorkflowItemsData?.currentAccount
            ?.accessibleWorkflowItemIds,
        refetchAccessibleWorkflowItems
      },
      isLoggedIn,
      hasUnreadNotifications: Boolean(hasUnreadNotifications),
      solutions,
      loadingAccountData: appQueryLoading
    }),
    [
      accessibleWorkflowItemsCount,
      appQueryLoading,
      currentAccount && currentAccount.id,
      hasUnreadNotifications,
      isLoggedIn,
      resolvedFeaturesCount,
      resolvedSettingData,
      refetchResolvedSettings,
      solutions.length
    ]
  )

  if (showLoader) {
    return <Loader />
  }

  return (
    <BugsnagErrorHandler currentAccount={appContextValue}>
      <AppContext.Provider value={appContextValue}>
        <HelmetProvider>
          <Helmet>
            <title>{getCurrentProduct()}</title>
            <link
              id="favicon"
              rel="shortcut icon"
              href={helmetItems[getCurrentProduct()].favIcon}
            />
            <link
              id="appleTouchIcon"
              rel="apple-touch-icon"
              href={helmetItems[getCurrentProduct()].touchIcon}
            />
            <link
              id="manifest"
              rel="manifest"
              href={helmetItems[getCurrentProduct()].manifest}
            />
          </Helmet>
          <GATagGlobal />
          <Pendo currentAccount={currentAccount} isLoggedIn={isLoggedIn} />
          <FilterProvider>
            <RequiredFieldsProvider>
              <ScrollProvider>
                <AppRouter />
              </ScrollProvider>
            </RequiredFieldsProvider>
          </FilterProvider>
        </HelmetProvider>
      </AppContext.Provider>
    </BugsnagErrorHandler>
  )
}

export default App
