import React from 'react'
import PropTypes from 'prop-types'
import { createUseStyles } from 'react-jss'
import { startCase } from 'lodash'

const CLIENT_PUBLIC_PATH_FOR_ICONS = '/icons.svg'

const sizes = {
  xSmall: 8,
  small: 10,
  medium: 12,
  normal: 20,
  large: 32,
  xLarge: 64
}

const useStyles = createUseStyles({
  icon: {
    width: ({ size }) => sizes[size] || 'inherit',
    height: ({ size }) => sizes[size] || 'inherit',
    transform: ({ rotate180 }) => rotate180 && 'rotate(180deg)',
    opacity: ({ disabled }) => (disabled ? 0.2 : 1)
  }
})

function GlyphIcon({ glyph, size, rotate180, disabled }) {
  const classes = useStyles({ size, rotate180, disabled })

  if (!glyph) {
    return null
  }

  return (
    <svg className={classes.icon}>
      <title>{startCase(glyph)}</title>
      <use xlinkHref={`${CLIENT_PUBLIC_PATH_FOR_ICONS}#${glyph}`} />
    </svg>
  )
}

GlyphIcon.propTypes = {
  glyph: PropTypes.string.isRequired,
  size: PropTypes.oneOf(Object.keys(sizes)),
  rotate180: PropTypes.bool,
  disabled: PropTypes.bool
}

GlyphIcon.defaultProps = {
  size: 'large',
  rotate180: false,
  disabled: false
}

export default GlyphIcon
