import PropTypes from 'prop-types'
import React, { forwardRef } from 'react'
import { createUseStyles } from 'react-jss'
import { useTranslation } from 'react-i18next'

import GlyphIcon from 'components/base/GlyphIcon'
import Text from 'components/typography/Text'
import Tooltip from 'components/popovers/Tooltip'
import useNetworkConnection from 'hooks/useNetworkConnection'

const useStyles = createUseStyles(({ colors }) => {
  const warningStyles = backgroundColor => props => ({
    display: 'flex',
    gap: '8px',
    alignItems: 'center',
    background: backgroundColor,
    padding: '4px 8px',
    borderRadius: props.rotateBorder ? '0 0 12px 12px' : '12px 12px 0 0'
  })

  return {
    unstableWarning: warningStyles(colors.accentYellow),
    noNetworkWarning: warningStyles(colors.negative),
    tooltip: {
      width: '60%'
    },
    banner: props => ({
      marginTop: props.top || 0,
      position: props.position,
      width: '100%',
      zIndex: 1,
      marginBottom: props.bottom || 0
    })
  }
})

const NetworkBanner = forwardRef(
  ({ rotateBorder, top, position, bottom }, ref) => {
    const { t } = useTranslation()
    const { networkConnection, hasNetworkConnection } = useNetworkConnection()

    const classes = useStyles({
      rotateBorder,
      top,
      position,
      bottom: !ref?.current && networkConnection < 5 ? -10 : bottom
    })

    React.useEffect(() => {
      const quill = ref?.current?.getEditor()
      if (quill) {
        const { root } = quill

        if (networkConnection < 5) {
          root.style.marginTop = '20px'
        } else {
          root.style.marginTop = '0'
        }
      }
    }, [hasNetworkConnection, networkConnection, ref])

    if (hasNetworkConnection && networkConnection < 5) {
      return (
        <div className={classes.banner}>
          <div className={classes.unstableWarning}>
            <GlyphIcon glyph="warning" size="medium" />
            <Text size="xSmall" weight="medium" color="primaryDark">
              {t('networkConnection.unStatbleInternet.title')}
            </Text>
            <Tooltip
              className={classes.tooltip}
              titleSize="xxSmall"
              delay={1000}
              title={t('networkConnection.unStatbleInternet.description')}
            >
              <Text
                as="a"
                size="xSmall"
                weight="medium"
                color="primaryDark"
                decoration="underline"
              >
                {t('networkConnection.learnMore')}
              </Text>
            </Tooltip>
          </div>
        </div>
      )
    }
    if (!hasNetworkConnection || networkConnection < 2) {
      return (
        <div className={classes.banner}>
          <div className={classes.noNetworkWarning}>
            <GlyphIcon glyph="unavailable" size="medium" />
            <Text size="xSmall" weight="medium" color="light">
              {t('networkConnection.noInternet.title')}
            </Text>
            <Tooltip
              className={classes.tooltip}
              titleSize="xxSmall"
              delay={1000}
              title={t('networkConnection.noInternet.description')}
            >
              <Text
                as="a"
                size="xSmall"
                weight="medium"
                color="light"
                decoration="underline"
              >
                {t('networkConnection.learnMore')}
              </Text>
            </Tooltip>
          </div>
        </div>
      )
    }

    return null
  }
)

NetworkBanner.prototype = {
  rotateBorder: PropTypes.bool,
  position: PropTypes.oneOf(['absolute', 'relative'])
}
NetworkBanner.defaultProps = {
  rotateBorder: false,
  position: 'absolute'
}

export default NetworkBanner
