import React from 'react'
import { createUseStyles } from 'react-jss'
import { useLocation } from 'react-router-dom'

import Text from 'components/typography/Text'
import TextLink from 'components/links/TextLink'

const useStyles = createUseStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',

    '& > p:last-child': {
      marginTop: 20
    }
  }
})

function SNSiteDownPage() {
  const location = useLocation()
  const classes = useStyles()

  const redirectionURL = `https://${import.meta.env.VITE_PORTAL_SITE_BASE_URL}${
    location.pathname
  }${location?.search || ''}`

  return (
    <div className={classes.container}>
      <Text size="xxLarge" color="light">
        SNChart Express is now DocBuddy.
      </Text>
      <Text size="xxLarge" color="light">
        Please visit{' '}
        <TextLink
          size="xxLarge"
          color="tertiary"
          label={import.meta.env.VITE_PORTAL_SITE_BASE_URL}
          type="text/html"
          href={redirectionURL}
        />{' '}
        to continue using Op Note.
      </Text>
      <Text size="small" weight="light" color="light" fontStyle="italic">
        Note: Your credentials are the same and all your data has been
        transferred.
      </Text>
    </div>
  )
}

export default SNSiteDownPage
