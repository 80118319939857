import { useContext } from 'react'

import AppContext from 'contexts/AppContext'

export const settingTypes = Object.freeze({
  AUTOMATIC_AI_NOTE_DELETION: 'automatic_ai_note_deletion',
  DEFAULT_APPOINTMENTS_VIEW: 'default_appointments_view',
  DEFAULT_TAB: 'default_tab',
  ENCOUNTER_LABEL: 'encounter_label',
  LOCATION_LABEL: 'location_label',
  SCHEDULE_LIST_LABEL: 'schedule_list_label',
  SCHEDULE_SURGERIES_LABEL: 'schedule_surgeries_label',
  HAS_SIDEBAR_COLLAPSED: 'has_sidebar_collapsed',
  AUTOMATICALLY_SEND_OP_REPORT: 'automatically_send_op_report',
  MANAGE_OPERATIVE_NOTE_IMAGES: 'manage_operative_note_images',
  ENABLE_AI_CONSENT_FORM_REQUIREMENT: 'enable_ai_consent_form_requirement',
  AUTOMATICALLY_FAX_OP_REPORT: 'automatically_fax_op_report'
})

const useSetting = settingType => {
  const { resolvedSettings } = useContext(AppContext)

  return resolvedSettings && resolvedSettings[settingType]
}

export const useAccountSetting = (account, settingType) =>
  account?.resolvedSettings?.[settingType]

export default useSetting
