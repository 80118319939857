import { gql } from '@apollo/client'
import { sortBy } from 'lodash'

import useFeature, { featureTypes } from 'hooks/useFeature'
import useQuery from 'hooks/useQuery'
import useSearchInput from 'hooks/useSearchInput'
import { filterKeys } from 'contexts/FilterContext'

export const Provider = {}

Provider.fragments = {
  baseDetails: gql`
    fragment Provider_base_details on Account {
      id
      fullName
      lastName
      clinicProvider {
        id
      }
    }
  `,
  practices: gql`
    fragment Provider_practices on Account {
      practices {
        id
        name
      }
    }
  `
}

Provider.fragments = {
  ...Provider.fragments,
  printableDetails: gql`
    fragment Provider_printable_details on Account {
      email
      phoneNumber
      signatureThumbNormal
      speciality

      ...Provider_base_details
      ...Provider_practices
    }
    ${Provider.fragments.baseDetails}
    ${Provider.fragments.practices}
  `
}

export const PROVIDERS_SEARCH_QUERY = gql`
  query ProvidersSearchQuery(
    $filter: ProviderFilterInput!
    $withPractices: Boolean!
  ) {
    providers(filter: $filter) {
      id
      fullName
      shareToken

      practices @include(if: $withPractices) {
        id
        name
      }
    }
  }
`

export const useSelectFollowableProviders = practices => {
  const canUsePractices = useFeature(featureTypes.USE_PRACTICES)
  const { data: { providers = [] } = {} } = useQuery(PROVIDERS_SEARCH_QUERY, {
    variables: {
      first: 100,
      skip: 0,
      sort: 'name',
      sortDirection: 'asc',
      filter: {},
      withPractices: canUsePractices
    },
    update: true
  })

  const commonPractices = practices
    ? practices.map(practice => practice.id)
    : []
  const practiceIdsOf = provider =>
    provider?.practices && provider.practices.map(practice => practice.id)
  const filteredProviders = canUsePractices
    ? providers.filter(provider =>
      commonPractices.some(item => practiceIdsOf(provider).includes(item))
    )
    : providers

  return filteredProviders
}

export const useSelectProvider = (
  { isMultiSelect, showPractice, filterKey, excludeProvidersIds } = {
    isMultiSelect: false,
    showPractice: false,
    filterKey: null,
    excludeProvidersIds: null
  }
) =>
  useSearchInput(
    PROVIDERS_SEARCH_QUERY,
    search => ({
      filter: {
        search,
        status: 'active',
        excludes: excludeProvidersIds
      },
      withPractices: showPractice
    }),
    ({ providers = [] }) =>
      providers.map(provider => {
        let label = provider.fullName
        if (showPractice && provider.practices.length > 0) {
          const practicesList = provider.practices.map(
            practice => practice.name
          )
          label += ` (${practicesList.join(', ')})`
        }

        return { label, value: provider.id, shareToken: provider.shareToken }
      }),
    filterKey || (isMultiSelect ? filterKeys.PROVIDERS : filterKeys.PROVIDER)
  )

export const getInitialProviderOption = (currentAccount, isMultiSelect) => {
  if (!currentAccount) return undefined

  if (currentAccount.type === 'Provider') {
    const option = {
      label: currentAccount.fullName,
      value: currentAccount.id
    }
    return isMultiSelect ? [option] : option
  }
  return isMultiSelect ? [] : null
}

export const sortByProviderName = providers =>
  sortBy(providers, provider => provider.fullName)

export default {
  Provider,
  getInitialProviderOption,
  PROVIDERS_SEARCH_QUERY,
  useSelectProvider,
  sortByProviderName
}
