import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { useState } from 'react'
import { createUseStyles } from 'react-jss'

import accessibleOnClick from 'lib/accessibleOnClick'
import { getProductTheme } from 'lib/CheckProduct'

const useStyles = createUseStyles(({ typography, colors }) => ({
  text: {
    color: props => colors[props.color],
    fontFamily: typography.fontFamilyVariants.primary,
    fontSize: props => typography.fontSizeVariants[props.size],
    fontStyle: props => props.fontStyle,
    fontWeight: props => typography.fontWeightVariants[props.weight],
    lineHeight: props => typography.lineHeightVariants[props.lineHeight],
    textAlign: props => props.align,
    textTransform: props => props.transform,
    textDecoration: props => props.decoration,
    letterSpacing: props =>
      typography.fontLetterSpacingVariants[props.letterSpacing],
    whiteSpace: props => props.whiteSpace,
    display: props => (props.inline ? 'inline' : 'block'),
    '& > $strong': {
      fontWeight: typography.fontWeightVariants.semibold
    }
  },
  showOrHideText: {
    color: colors.tertiary,
    cursor: 'pointer'
  }
}))

function Text({
  align,
  as: Component,
  children,
  className,
  color,
  decoration,
  letterSpacing,
  lineHeight,
  size,
  transform,
  weight,
  hideLargeText,
  sliceLength,
  whiteSpace,
  inline,
  fontStyle,
  ...props
}) {
  const classes = useStyles({
    align,
    color,
    decoration,
    letterSpacing,
    lineHeight,
    size,
    transform,
    weight,
    whiteSpace,
    inline,
    fontStyle
  })

  const [isReadMore, setIsReadMore] = useState(true)
  const text = children
  const toggleReadMore = () => {
    setIsReadMore(!isReadMore)
  }

  if (hideLargeText) {
    return (
      <Component className={classNames(classes.text, className)} {...props}>
        {isReadMore ? text?.slice(0, sliceLength) : text}
        {text && (
          <span
            className={classes.showOrHideText}
            {...accessibleOnClick(toggleReadMore)}
          >
            {isReadMore ? '...read more' : ' show less'}
          </span>
        )}
      </Component>
    )
  }

  return (
    <Component className={classNames(classes.text, className)} {...props}>
      {children}
    </Component>
  )
}

Text.propTypes = {
  align: PropTypes.oneOf(['left', 'center', 'right']),
  as: PropTypes.oneOf(['a', 'p', 'span', 'label', 'pre']),
  className: PropTypes.string,
  color: PropTypes.oneOf(Object.keys(getProductTheme().colors)),
  decoration: PropTypes.oneOf(['auto', 'overline', 'underline']),
  letterSpacing: PropTypes.oneOf(
    Object.keys(getProductTheme().typography.fontLetterSpacingVariants)
  ),
  lineHeight: PropTypes.oneOf(
    Object.keys(getProductTheme().typography.lineHeightVariants)
  ),
  size: PropTypes.oneOf([
    'xxxLarge',
    'xxLarge',
    'xLarge',
    'large',
    'medium',
    'small',
    'xSmall',
    'xxSmall',
    'xxxSmall'
  ]),
  transform: PropTypes.oneOf(['none', 'capitalize', 'lowercase', 'uppercase']),
  weight: PropTypes.oneOf([
    'bold',
    'semibold',
    'medium',
    'regular',
    'light',
    'extralight'
  ]),
  hideLargeText: PropTypes.bool,
  sliceLength: PropTypes.number,
  whiteSpace: PropTypes.string,
  inline: PropTypes.bool
}

Text.defaultProps = {
  align: 'left',
  as: 'p',
  className: null,
  color: 'secondary',
  decoration: 'auto',
  letterSpacing: 'normal',
  lineHeight: 'normal',
  size: 'small',
  transform: 'none',
  weight: 'light',
  hideLargeText: false,
  sliceLength: 150,
  whiteSpace: 'pre-line',
  inline: false
}

export default Text
