import React from 'react'
import { Form, Field } from 'react-final-form'
import * as yup from 'yup'
import { useTranslation } from 'react-i18next'

import AuthFormFooter from 'components/auth/AuthFormFooter'
import AuthTextInput from 'components/inputs/AuthTextInput'
import Button from 'components/buttons/Button'
import FormattedPhoneInput from 'components/inputs/FormattedPhoneInput'
import validatePhone from 'lib/validatePhone'
import validateSchema from 'lib/validators'
import {
  hasExternalLogin,
  hasInternalPassword,
  isExternalRedirectLogin
} from 'models/Account'

const createSchema = t => shouldCheckPassword =>
  yup.object().shape({
    firstName: yup
      .string()
      .required(
        t('completeRegistrationPage.form.fields.firstName.validationMessage')
      ),
    lastName: yup
      .string()
      .required(
        t('completeRegistrationPage.form.fields.lastName.validationMessage')
      ),
    phoneNumber: yup
      .string()
      .test(
        'valid-phone',
        t('completeRegistrationPage.form.fields.phoneNumber.validationMessage'),
        validatePhone
      ),
    ...(shouldCheckPassword && {
      password: yup
        .string()
        .required(
          t('completeRegistrationPage.form.fields.password.validationMessage')
        ),
      passwordConfirmation: yup
        .string()
        .oneOf(
          [yup.ref('password'), null],
          t(
            'completeRegistrationPage.form.fields.passwordConfirmation.validationMessage'
          )
        )
        .required(
          t(
            'completeRegistrationPage.form.fields.passwordConfirmation.requiredMessage'
          )
        )
    })
  })

function AccountRegistrationForm({ account, onFormSubmit }) {
  const { t } = useTranslation()
  const schema = createSchema(t)
  const { loginType } = account
  const initialValues = { externalLogin: account.externalLogin }

  return (
    <Form
      onSubmit={onFormSubmit}
      validate={validateSchema(schema(hasInternalPassword(account)))}
      initialValues={initialValues}
      render={({ handleSubmit }) => (
        <form onSubmit={handleSubmit}>
          <Field
            component={AuthTextInput}
            name="firstName"
            placeholder={t(
              'completeRegistrationPage.form.fields.firstName.placeholder'
            )}
            showValidationMessage
          />
          <Field
            component={AuthTextInput}
            name="lastName"
            placeholder={t(
              'completeRegistrationPage.form.fields.lastName.placeholder'
            )}
            showValidationMessage
          />
          <Field
            component={FormattedPhoneInput}
            type="tel"
            name="phoneNumber"
            placeholder={t(
              'completeRegistrationPage.form.fields.phoneNumber.placeholder'
            )}
            showValidationMessage
            variant="large"
            weight="semibold"
            fontSize="small"
          />
          {hasInternalPassword(account) && (
            <div>
              <Field
                component={AuthTextInput}
                name="password"
                placeholder={t(
                  'completeRegistrationPage.form.fields.password.placeholder'
                )}
                type="password"
                showValidationMessage
              />
              <Field
                component={AuthTextInput}
                name="passwordConfirmation"
                placeholder={t(
                  'completeRegistrationPage.form.fields.passwordConfirmation.placeholder'
                )}
                type="password"
                showValidationMessage
              />
            </div>
          )}
          {hasExternalLogin(account) && (
            <Field
              component={AuthTextInput}
              name="externalLogin"
              placeholder={
                isExternalRedirectLogin(loginType)
                  ? t(
                    'completeRegistrationPage.form.fields.externalLogin.placeholderExternal'
                  )
                  : t(
                    'completeRegistrationPage.form.fields.externalLogin.placeholderEmr'
                  )
              }
              showValidationMessage
            />
          )}
          <AuthFormFooter>
            <Button
              label={t('completeRegistrationPage.form.buttons.register')}
            />
          </AuthFormFooter>
        </form>
      )}
    />
  )
}

export default AccountRegistrationForm
