import { useState, useEffect } from 'react'

function useNetworkConnection() {
  const [isOnline, setIsOnline] = useState(navigator.onLine)
  const [connectionDownlink, setConnectionDownlink] = useState(
    navigator.connection?.downlink || 0
  )

  useEffect(() => {
    // Event handler for online/offline status
    const handleNetworkStatus = () => {
      setIsOnline(navigator.onLine)
    }

    // Event handler for connection changes
    const handleConnectionChange = () => {
      setConnectionDownlink(navigator.connection?.downlink || 0)
    }

    // Add event listeners
    window.addEventListener('online', handleNetworkStatus)
    window.addEventListener('offline', handleNetworkStatus)

    const {connection} = navigator
    if (connection && connection.addEventListener) {
      connection.addEventListener('change', handleConnectionChange)
    }

    // Cleanup on unmount
    return () => {
      window.removeEventListener('online', handleNetworkStatus)
      window.removeEventListener('offline', handleNetworkStatus)

      if (connection && connection.removeEventListener) {
        connection.removeEventListener('change', handleConnectionChange)
      }
    }
  }, [])

  return {
    hasNetworkConnection: isOnline,
    networkConnection: connectionDownlink
  }
}

export default useNetworkConnection
