import PropTypes from 'prop-types'
import React from 'react'
import { createUseStyles } from 'react-jss'

import { getProductTheme } from 'lib/CheckProduct'

const useStyles = createUseStyles({
  header: {
    justifyContent: 'space-between',
    alignItems: 'center',
    display: 'flex',
    padding: '20px 50px'
  }
})

function FullScreenModalHeader({ children }) {
  const classes = useStyles()

  return <header className={classes.header}>{children}</header>
}

FullScreenModalHeader.propTypes = {
  background: PropTypes.oneOf(Object.keys(getProductTheme().colors)),
  variant: PropTypes.oneOf(['compact', 'default'])
}

FullScreenModalHeader.defaultProps = {
  background: 'primary',
  variant: 'default'
}

export default FullScreenModalHeader
